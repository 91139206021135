<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">
            {{
              $t("message.edit_m", {
                m: $t("message.category"),
              })
            }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit_check(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0" v-loading="loadingData">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-form ref="form" :model="form" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item
                  :label="$t('message.name')"
                  class="label_mini"
                  prop="name"
                >
                  <el-input
                    :placeholder="$t('message.name')"
                    v-model="form.name"
                    size="medium"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  :label="$t('message.parent_id')"
                  class="label_mini"
                >
                  <select-category
                    :size="'medium'"
                    :placeholder="columns.parent_id.title"
                    :id="form.parent_id"
                    :selected_id="form.id"
                    v-model="form.parent_id"
                  >
                  </select-category>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import selectCategory from "@/components/inventory/select-category";

export default {
  mixins: [form, drawer, show],
  components: {
    selectCategory,
  },
  name: "categories",
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters({
      rules: "categories/rules",
      model: "categories/model",
      columns: "categories/columns",
    }),
  },
  methods: {
    ...mapActions({
      update: "categories/update",
      show: "categories/show",
      updateInventory: "categories/inventory",
      categoryAnalize: "categories/categoryAnalize",
    }),

    submit_check(){
      this.categoryAnalize({category_id: this.form.parent_id, analize_type: 'create'})
        .then(res => {
          if(res.data.result.success){
            this.submit(true)
          }else{

            this.$confirm(
              res.data.result.message,
              this.$t('message.warning'), {
                confirmButtonText: this.$t('message.yes'),
                cancelButtonText: this.$t('message.no'),
                type: "warning"
              }
            )
            .then(() => {
              this.submit(true)
            })
            .catch(() => {
              this.$message({
                type: "warning",
                message: this.$t('message.operation_canceled')
              });
            });
          }

        })
        .catch((err) => {
          this.$alert(err);
        });

    },

    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.update(this.form)
            .then((res) => {
              if(res.data.result.success){
                this.loadingButton = false;
                this.$alert(res);
                this.parent().listChanged();
                this.updateInventory();
                if (res.status == 200) this.close();
              }else{
                this.$info_message(res);
              }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
  },
};
</script>
