<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                {{ $t("message.categories") }}
              </div>
              <div class="block-sarche">
                <div class="header__search">
                  <crm-input
                    :size="'small'"
                    :className="'w100'"
                    :class="mode ? 'input__day' : 'input__night'"
                    v-model="filterForm.search"
                    :icon="'el-icon-search'"
                  ></crm-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right">
            <crm-create-and-column-settings
              :permission="$options.name"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              @c-create="drawerCreate = true"
              :columns="columns"
              @c-change="updateColumn"
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart custom__scroll">
        <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered" v-loading="loadingData">
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>

              <th v-if="columns.name.show">
                {{ columns.name.title }}
              </th>

              <th v-if="columns.parent_id.show">
                {{ columns.parent_id.title }}
              </th>

              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>

              <th v-if="columns.updated_at.show">
                {{ columns.updated_at.title }}
              </th>

              <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.id"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>

              <th v-if="columns.name.show">
                <crm-input
                  :placeholder="columns.name.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.name"
                ></crm-input>
              </th>

              <th v-if="columns.parent_id.show">
                <select-category
                  :placeholder="columns.parent_id.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.parent_id"
                ></select-category>
              </th>

              <th v-if="columns.created_at.show">
                <crm-date-picker
                  :placeholder="columns.created_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.created_at"
                ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                <crm-date-picker
                  :placeholder="columns.updated_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.updated_at"
                ></crm-date-picker>
              </th>

              <th class="settinW" v-if="columns.settings.show"></th>
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr v-for="city in list" :key="city.id" class="cursor-pointer">
              <td v-if="columns.id.show">
                {{ city.id }}
              </td>

              <td v-if="columns.name.show">
                {{ city.name }}
              </td>
              <td v-if="columns.parent_id.show">
                {{ city.parent ? city.parent.name : "" }}
              </td>
              <td v-if="columns.created_at.show">
                {{ city.created_at }}
              </td>

              <td v-if="columns.updated_at.show">
                {{ city.updated_at }}
              </td>

              <td v-if="columns.settings.show" class="settings-td">
                <crm-settings
                  :name="$options.name"
                  :model="city"
                  :actions="actions"
                  :permissionShow="'cities.update'"
                  :permissionDestroy="'cities.delete'"
                  @edit="edit"
                  @delete="destroy"
                ></crm-settings>
              </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
          <!-- <Pagination /> -->
        </div>
      </div>

      <div class="app-modal app-modal__full modal-color-bg">
        <el-drawer
          :with-header="false"
          :visible.sync="drawerCreate"
          ref="drawerCreate"
          size="70%"
          @opened="drawerOpened('drawerCreateChild')"
          @closed="drawerClosed('drawerCreateChild')"
        >
          <div>
            <crm-create ref="drawerCreateChild" drawer="drawerCreate">
            </crm-create>
          </div>
        </el-drawer>

        <el-drawer
          :with-header="false"
          :visible.sync="drawerUpdate"
          size="70%"
          ref="drawerUpdate"
          @opened="drawerOpened('drawerUpdateChild')"
          @closed="drawerClosed('drawerUpdateChild')"
        >
          <crm-update
            :selectedItem="selectedItem"
            ref="drawerUpdateChild"
            drawer="drawerUpdate"
          ></crm-update>
        </el-drawer>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import Pagination from "@/components/el-pagination";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import selectCategory from "@/components/inventory/select-category";

import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "category",
  mixins: [list],
  components: {
    Pagination,
    CrmCreate,
    CrmUpdate,
    selectCategory,
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      list: "categories/list",
      columns: "categories/columns",
      pagination: "categories/pagination",
      filter: "categories/filter",
      sort: "categories/sort",
      updateInventory: "categories/inventory",
      mode: "MODE"
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "categories/index",
      setPagination: "categories/setPagination",
      updateSort: "categories/updateSort",
      updateFilter: "categories/updateFilter",
      updateColumn: "categories/updateColumn",
      updatePagination: "categories/updatePagination",
      show: "categories/show",
      empty: "categories/empty",
      delete: "categories/destroy",
      refreshData: "categories/refreshData",
      categoryAnalize: "categories/categoryAnalize",
    }),

    destroy(model) {
      this.categoryAnalize({category_id: model.id, analize_type: 'delete'})
        .then(res => {
          if(res.data.result.success){
            this.delete(model.id)
              .then((res) => {
                this.updateInventory();
                this.$alert(res);
                this.fetchData();
              })
              .catch((err) => {
                this.$alert(err);
              });
          }else{
            this.$notify({
              title: this.$t('message.warning'),
              type: "warning",
              offset: 130,
              message: res.data.result.message
            }); 
          }

        })
        .catch((err) => {
          this.$alert(err);
        });
      
      
    },
  },
  beforeRouteLeave (to, from, next) {
      this.$store.commit('categories/EMPTY_LIST');
      next()
  },
};
</script>

